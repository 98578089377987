document.addEventListener("turbolinks:load", () => {
  const elems = document.querySelectorAll("[data-action='set-on-click']");

  Array.from(elems).forEach(elem => {
    const target = elem.getAttribute("data-target");
    const value = elem.getAttribute("data-value");

    elem.addEventListener("click", (e) => {
      e.preventDefault();

      const input = document.querySelector(target);
      if (!input) { return; }

      const affectedElements = Array.from(elems).filter(elem => elem.getAttribute("data-target") === target);
      affectedElements.forEach(elem => elem.classList.remove("active"));
      elem.classList.add("active");

      input.value = value;
      input.dispatchEvent(new Event("change"));
    });
  });
});

