import Rails from "@rails/ujs";

document.addEventListener("turbolinks:load", () => {
  const event = new Event("confirmation_form:enable");
  document.dispatchEvent(event);
});

document.addEventListener("confirmation_form:enable", () => {
  const form = document.querySelector("#confirmation_token_form");
  
  if (form) { confirmationForm(form); };
}, false);

document.addEventListener("confirmation_form:focus", () => {
  const form = document.querySelector("#confirmation_token_form");
  
  if (form) { focusInput(form); };
}, false);

document.addEventListener("confirmation_form:error", () => {
  const form = document.querySelector("#confirmation_token_form");
  
  if (form) { errorInputs(form); };
}, false);

function errorInputs(form) {
  const inputs = form.querySelectorAll("input.input-digit");

  inputs.forEach((input) => {
    input.classList.add("error");
  });
}

function focusInput(form) {
  const inputs = form.querySelectorAll("input.input-digit");
  const input = Array.from(inputs).find((input) => input.value === "");

  if (input) { input.focus(); }
}

function confirmationForm(form) {
  const inputs = form.querySelectorAll("input.input-digit");

  inputs.forEach((input) => {
    input.addEventListener("input", (e) => {
      const value = e.target.value;
      const nextInput = e.target.nextElementSibling;

      if (value.length === 1) {
        if (nextInput) { nextInput.focus(); }
      }

      submitToken(form);
    });

    input.addEventListener("keydown", (e) => {
      if (e.keyCode === 8 || e.keyCode === 46) {
        if (e.target.previousElementSibling) {
          e.target.value = "";
          e.target.previousElementSibling.focus();
        }
      } else if (e.target.value.length === 1) {
        if (e.target.nextElementSibling) { e.target.nextElementSibling.focus(); }
      }

      submitToken(form);
    });

    input.addEventListener("paste", (e) => {
      const value = e.clipboardData.getData("text/plain");
      const inputArray = Array.from(inputs);

      if (value.length === 6) {
        inputArray.forEach((input, index) => {
          input.value = value[index];
        });
      }

      submitToken(form);
    });
  });
}

function submitToken(form) {
  const inputs = form.querySelectorAll("input.input-digit");
  const token = Array.from(inputs).map((input) => input.value).join("");

  if (token.length === 6) {
    form.querySelector("input[name='confirmation_token']").value = token;
    Rails.fire(form, "submit");
  }
}
