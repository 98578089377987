document.addEventListener("turbolinks:load", () => {
  // Remove class on click
  addCssOnClick();
  removeCssOnClick();
});

function addCssOnClick() {
  const elems = document.querySelectorAll("[data-add-css-on-click]");
  Array.from(elems).forEach((elem) => {
    const target = document.querySelector(elem.getAttribute("data-add-css-on-click"));
    const klass = elem.getAttribute("data-add-css-on-click-class");
    elem.addEventListener("click", (e) => {
      e.preventDefault();
      target.classList.add(klass);
    });
  });
}

function removeCssOnClick() {
  const elems = document.querySelectorAll("[data-remove-css-on-click]");
  Array.from(elems).forEach((elem) => {
    const target = document.querySelector(elem.getAttribute("data-remove-css-on-click"));
    const klass = elem.getAttribute("data-remove-css-on-click-class");
    elem.addEventListener("click", (e) => {
      e.preventDefault();
      target.classList.remove(klass);
    });
  });
}




