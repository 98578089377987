document.addEventListener("turbolinks:load", () => {
  const feedbackButton = document.querySelector("#feedback-button");
  if (!feedbackButton) { return; };

  feedbackButton.addEventListener("click", (e) => {
    e.preventDefault();
    feedbackButton.remove();
    document.querySelector("#email-confirmation-block").classList.remove("is-hidden");
  })

  const guestFeedbackRating = document.querySelector("#guest_feedback_rating");

  if (!guestFeedbackRating) { return; }

  guestFeedbackRating.addEventListener("change", (e) => {
    e.preventDefault();
    const descriptionBlock = document.querySelector("#guest-feedback-description-block");
    const submitBlock = document.querySelector("#guest-feedback-submit-block");
    descriptionBlock.classList.remove("hidden");
    submitBlock.classList.remove("hidden");
  });
});
