import "core-js/stable";
import "regenerator-runtime/runtime";

import baguetteBox from "baguettebox.js";
import Flickity from "flickity";
import "flickity-imagesloaded";
import "flickity/dist/flickity.min.css";
import LazyLoad from "vanilla-lazyload";

import Turbolinks from "turbolinks";
import Rails from "@rails/ujs";

import "src/sites/qazanova_main/scss/main.scss";

import "src/sites/qazanova_main/images";

import "src/sites/qazanova_main/js/confirmation_token";
import "src/sites/qazanova_main/js/set_on_click";
import "src/sites/qazanova_main/js/important_rules";
import "src/sites/qazanova_main/js/back";
import "src/sites/qazanova_main/js/feedbacks";
import "src/sites/qazanova_main/js/helpers";
import "src/sites/qazanova_main/js/modal";
import "src/sites/qazanova_main/js/visitor_log";
import "src/sites/qazanova_main/js/menu";

Rails.start();
Turbolinks.start();

document.addEventListener("turbolinks:load", () => {
  // Reset scroll position if no anchor is provided in url
  if (!window.location.hash.length) {
    window.scrollTo(0, 0);
  }
  
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
  });

  lazyLoadInstance.update();

  const popupClosers = document.querySelectorAll("[data-popup-close]");

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw_v3.js', { scope: "/" })
      .then(() => navigator.serviceWorker.ready.then((worker) => {
        worker.sync.register('syncdata');
      }))
      .catch((err) => console.log(err));
  }

  Array.from(popupClosers).forEach(popupClose => {
    popupClose.addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(popupClose.dataset.popupClose).remove();
    })
  })

  if (!document.querySelector(".photos-column")) { return; }

  baguetteBox.run(".photos-column");
  baguetteBox.run(".mobile-gallery");

  var flickityEntity = null;

  actualResizeHandler();

  window.addEventListener("resize", resizeThrottler, false);

  var resizeTimeout;
  function resizeThrottler() {
    if ( !resizeTimeout ) {
      resizeTimeout = setTimeout(function() {
        resizeTimeout = null;
        actualResizeHandler();

       }, 66);
    }
  }

  function actualResizeHandler() {
    if (window.innerWidth < 769) {
      if (flickityEntity) { return; }

      const holder = document.querySelector(".photos-column");

      if (!holder) { return; }

      flickityEntity = new Flickity(holder, {
        cellAlign: "left",
        contain: true,
        imagesLoaded: true
      });
    } else {
      if (flickityEntity) {
        flickityEntity.destroy();
        flickityEntity = null;
      }
    }
  }
});

// Emit event for async loading of script
document.dispatchEvent(new Event("turbolinks:load"));
